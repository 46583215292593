import React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, CssBaseline, Drawer, Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Menu } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore, Dashboard, Api, PlayCircle, AccountCircle, CreditCard } from '@mui/icons-material';

const drawerWidth = 240;

const Sidebar: React.FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white', boxShadow: 'none' }}>
                <Toolbar sx={{
                    borderBottom: 1,
                    borderColor: 'grey.300',
                }}>
                    <Box
                        component="img"
                        sx={{
                            height: 64, // Adjust based on your image size
                            padding: 1,
                        }}
                        alt="Fantastic Fundraising Banner"
                        src="/ff-logo-banner.jpeg" // Replace with your banner image URL
                    />
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <MenuLink title="Dashboard" href="/" icon={<Dashboard />} />
                        <NestedMenuParent title="API" icon={<Api />}>
                            <NestedMenuChild title="API Key" href="/apikey" />
                            <NestedMenuChild title="API Logs" href="/apilogs" />
                            <NestedMenuChild title="API Docs" href="/docs" />
                        </NestedMenuParent>
                        <MenuLink title="Try it now" href="/queryplayground" icon={<PlayCircle />} />
                    </List>
                    <Divider />
                    <List>
                        <MenuLink title="Billing" href="/billing" icon={<CreditCard />} />
                        <MenuLink title="Account" href="/accountsettings" icon={<AccountCircle />} />
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
}

const MenuLink: React.FC<{ title: string, href: string, icon: React.ReactNode }> = ({ title, href, icon }) => {
    const location = useLocation();

    const is_active = (href: string) => location.pathname === href;
    return (
        <ListItem key={title} disablePadding sx={{ backgroundColor: is_active(href) ? '#f0f0f0' : 'inherit'}}>
            <ListItemButton component="a" href={href}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    );
};

const NestedMenuParent: React.FC<{ title: string, icon: React.ReactNode, children: React.ReactNode }> = ({ title, icon, children }) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    )
};

const NestedMenuChild: React.FC<{ title: string, href: string }> = ({ title, href }) => {   
    const location = useLocation();

    const is_active = (href: string) => location.pathname === href;
    return (
        <ListItemButton component="a" href={href} sx={{ pl: 6, backgroundColor: is_active(href) ? '#f0f0f0' : 'inherit'}}>
            <ListItemIcon
                sx={{
                    borderLeft: '1px solid grey',
                    height: 24,
                    minWidth: 24,
                }}
            />
            <ListItemText primary={title} />
        </ListItemButton>
    );
};


export default Sidebar;