import React from 'react';
import { Box, TextField, Button, Card, CardContent, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';


const TermsPage: React.FC = () => {
    return (
        <Box padding={3} >
            <Typography variant="h4">Terms and Conditions</Typography>
            <Box component="ol" sx={{
                pl: 3,
            }}>
                <Typography component="li" variant='h5' gutterBottom sx={{ mt: 3 }}>
                    APPLICATION
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>1.1  </b>These Terms and Conditions of Use (this "<b>Agreement</b>") apply to your use of and/or access to the Site (as defined below) that is owned, operated or controlled by the Company and is intended for and directed to Customers of the Company only.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>1.2  </b> The Company provides the Site to you subject to this Agreement. By accessing and/or using the Site, you (the "<b>Customer</b>") agree to be bound by these Terms and Conditions of Use. If you do not accept these Terms and Conditions of Use, you are not authorised to use and/or access the Site.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>1.3  </b> This Agreement may be modified from time to time. The Company has the right at any time in its entire discretion to change and/or modify these Terms and Conditions of Use and such changes and/or modifications are to be effective immediately upon posting to the Site. Please read this Agreement carefully to ensure you understand the Terms and Conditions that apply at that time. Your continued use of the Site after such posting will signify your acceptance of such changes and/or modifications.
                </Typography>
                <Typography component="li" variant="h5" gutterBottom sx={{ mt: 3 }}>
                    DEFINITIONS AND INTERPRETATION
                </Typography>
                <Typography variant="body1">
                    <b>2.1</b> Definitions
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    In this Agreement, unless the context indicates otherwise:
                </Typography>
                <List>
                    <DefinitionListItem primary="Applicable Law">
                        means any and all applicable laws, statuses, orders, rules, treaties, decree, regulations, directives, edicts, bylaws, schemes, warrants, other instruments made under or to be made under any statute, any exercises of the royal prerogative and codes of conduct and regulatory rules or guidelines, whether local, national, international or otherwise existing from time to time, together with any other similar instrument having legal effect in the relevant circumstances;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Company">
                        The company means Concadence Group LLC, a Limited Liability Company with Entity Identification Number [TODO], having its registered address at [TODO];
                    </DefinitionListItem>
                    <DefinitionListItem primary="Customer">
                        means the Party who is permitted to receive the Data and Services from the Company as specified in this Agreement;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Data">
                        means all data, content, information and/or materials provided by the Company to the Customer through the Site, from the Company’s Charitable Data API service and/or via electronic mail communication between the Company and the Customer for the purposes of this Agreement;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Data License Agreement">
                        means the agreement entered into between the Company and the Customer for the sale of bulk Data by the Company to the Customer;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Force Majeure">
                        means an event or sequence of events beyond a Party's reasonable control preventing or delaying it from performing its obligations under this Agreement save that an inability to pay is not a Force Majeure event;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Party and Parties">
                        means the Company and the Customer, and ‘Party’ shall mean either one of them;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Personnel">
                        means a Party’s affiliates, officers, agents, partners and employees, staff, professional advisers or appointed individuals who are key to or engaged in or in relation to the performance or management of this Agreement;
                    </DefinitionListItem>
                    <DefinitionListItem primary="Services">
                        means, as the context permits, the service or services provided by the Company to the Customer in relation to the use of the Site or to access the Data including but not limited to the Company’s API service; and
                    </DefinitionListItem>
                    <DefinitionListItem primary="Site">
                        means the website with domain name proxycurl.com and nubela.co or such other name which the Company provides to the Customer in respect of this Agreement.
                    </DefinitionListItem>
                </List>
                <Typography variant="body1">
                    <b>2.2</b> Interpretation
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    In this Agreement, unless the contrary intention appears:
                </Typography>
                <List>
                    <RegularListItem>
                        words importing a gender include any other gender;
                    </RegularListItem>
                    <RegularListItem>
                        words in the singular include the plural and words in the plural include the singular;
                    </RegularListItem>
                    <RegularListItem>
                        clause headings are for convenient reference only and have no effect in the interpretation of this Agreement or limiting or extending the language of provisions to which they refer;
                    </RegularListItem>
                    <RegularListItem>
                        words importing a person includes a partnership and a body whether corporate or otherwise;
                    </RegularListItem>
                    <RegularListItem>
                        a reference to dollars is a reference to the U.S. dollars;
                    </RegularListItem>
                    <RegularListItem>
                        references to time will mean Pacific time, unless otherwise stated;
                    </RegularListItem>
                    <RegularListItem>
                        a reference to any legislation or legislative provision includes any statutory modification, substitution or re-enactment of that legislation or legislative provision;
                    </RegularListItem>
                    <RegularListItem>
                        if any word or phrase is given a defined meaning, any other part of speech or other grammatical form of that word or phrase has a corresponding meaning;
                    </RegularListItem>
                    <RegularListItem>
                        references to books, records or other information include paper, electronically or magnetically stored data, film, microfilm, and information in any other form;
                    </RegularListItem>
                    <RegularListItem>
                        a reference to writing is a reference to any representation of words, figures or symbols, whether or not in a visible form;
                    </RegularListItem>
                    <RegularListItem>
                        if any conflict arises between the terms and conditions contained in the clauses of this Agreement, the terms and conditions of the Agreement prevail.
                    </RegularListItem>
                </List>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    OWNERSHIP, ACCESS AND LICENSE
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>3.1</b> The Site and all designs, names, logos and get-up within the Site are under common control and operated by the Company and/or its licensors. You may only take copies of any part of the Site for the purpose of using the Site in accordance with Article 4.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>3.2</b> The Company grants you a non-exclusive and non-transferable limited license to access and utilise the Site and Service and make use of the Data in accordance with and subject to the provisions contained in this Agreement.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>3.3</b> Subject to Article 4 in this Agreement, this license does not include any resale or commercial use of the Site, Service or the Data, any derivative use of the Site, Service or the Data or any use of data mining, scraping, robots, or similar data gathering and extraction software, technology or tools.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>3.4</b> Any unauthorised use of the Site, Service or the Data terminates your limited license with immediate effect. Notwithstanding this, the Company may revoke this limited license at any time for any or no reason.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    PERMITTED USE
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>4.1</b> You may only access and utilise the Site, Service and Data for your internal business purposes only and in accordance with the applicable law or regulation(s) as required by the state, governmental or regulatory authority.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>4.2</b> In the event of a bulk Data sale by the Company to the Customer, the Customer’s terms of use of the Site, Service and Data shall be subject to and governed by the Data License Agreement as entered into between the Company and the Customer.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 3 }} component="li">
                    PROHIBITED USE
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>5.1</b> Subject to Article 4 herein and unless expressly authorised by the Company in writing, you may not do, permit, or authorise the doing of any of the following acts in relation to the Site or Services:
                </Typography>
                <RegularListItem>
                    use in a way that is unlawful;
                </RegularListItem>
                <RegularListItem>
                    copy, reformat, reprocess, distribute, dispose of, duplicate, transmit, display, revise, perform, reproduce, publish, license, hyperlink, deep-link, create derivative works from, transfer, trade, sell or re-sell;
                </RegularListItem>
                <RegularListItem>
                    download (other than page caching) or modify any portion of it;
                </RegularListItem>
                <RegularListItem>
                    use in connection with any material which contains computer viruses or spyware or malware of any description or with any material which is designed to adversely affect the operation of any computer hardware or software or any communications network;
                </RegularListItem>
                <RegularListItem>
                    use in any manner in which simultaneous access by multiple unauthorised users is permitted;
                </RegularListItem>
                <RegularListItem>
                    use a personal or proxy account to register an account with the Company to access and utilise the Site for the purposes of downloading, transferring or copying Data for the benefit of another merchant or business;
                </RegularListItem>
                <RegularListItem>
                    frame, inline link or utilise other techniques to associate or juxtapose any of the Company’s name, logos, or other materials with advertisements and/or other information not originating from the Site;
                </RegularListItem>
                <RegularListItem>
                    translate, edit, reverse-engineer, decompile, or disassemble the Site or attempt to do so.
                </RegularListItem>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>5.2</b> The Company reserves the right to prevent or suspend your access to the Site and/or the Services immediately without notice if you do not comply with any part of this Agreement and/or and conditions or any applicable law.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>5.3</b> Notwithstanding Clause 5.2, the Company retains the right at its sole discretion to suspend or cease the operation of the Site from time to time or deny access to you or anyone to this Site, at any time and for any or no reason.
                </Typography>

                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    DISCLAIMERS
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.1</b> The Site, Services and all Data, information, content, materials included on or otherwise made available to you by the Company are provided on an ‘as is’ and ‘as available’ basis, unless otherwise specified in writing.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.2</b> While the Company will use reasonable efforts to include accurate and up-to-date information on the Site, the Company makes no representations or warranties of any kind, express or implied, as to the operation of the Site, provision of the Service or that the Data remains accurate, complete and up-to-date, or fit or suitable for any particular purpose. Any reliance you place on the Data is at your own sole risk.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.3</b> The Company does not warrant that the Site, Service or the Data or any information, content, materials or services included on or otherwise made available to you through the Site, Service their servers, or electronic mails sent from the Company are free of viruses or other harmful components.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.4</b> The Company makes no representations or warranties of any kind, express or implied, that it is in any way an official LinkedIn API.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.5</b> Nothing in the Site or the Data constitutes technical, financial, legal advice or any other type of advice and should not be relied on for any purposes.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>6.6</b> The Site may contain hyperlinks or references to external third-party websites. Any such hyperlinks or reference is provided for your convenience only. The Company has no control over third-party websites and accept no responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third-party website does not constitute an endorsement of such third party's website, products or services. Your use of a third-party site may be governed by the terms and conditions of that third-party site.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    LIMITATION OF LIABILITY
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>7.1</b> Under no circumstances shall the Company be liable to you for any loss or damage suffered (including without limitation direct or indirect losses) arising from your use of, access to, or reliance on, the Data, Service and the Site (including the downloading of any software, program or information).
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>7.2</b> To the maximum extent permitted by applicable law, the Company disclaims and excludes all liability (whether direct or indirect or consequential or economic incidental or special and whether arising in contract, tort, breach of statutory duty or otherwise) arising out of or in connection with your use of the Site, Service or the Data including without limitation any and all losses relating to or resulting from:
                </Typography>
                <RegularListItem>
                    the accuracy, reliability, completeness, suitability, merchantability or fitness for purpose of the Site or the Data;
                </RegularListItem>
                <RegularListItem>
                    any error or inaccuracies in any information or material within or relating to the Data or the Site;
                </RegularListItem>
                <RegularListItem>
                    the inability at any time to obtain access to any part of the Site or Service or the unavailability of the Data for whatsoever reason;
                </RegularListItem>
                <RegularListItem>
                    any reliance upon or use of or actions taken or not taken or decisions made on the basis of anything contained in the Site or the Data;
                </RegularListItem>
                <RegularListItem>
                    any computer viruses or spyware or malware of any description or any material which might adversely affect;
                </RegularListItem>
                <RegularListItem>
                    the operation of any computer hardware or software or any communications network which affects you as a result of you accessing the Site or the Data;
                </RegularListItem>
                <RegularListItem>
                    any representation or statement (whether express or implied) made in the Site or the Data.
                </RegularListItem>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>7.3</b> The Company shall not be liable to you for any loss or damage suffered by you as a result of any event of Force Majeure.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    INDEMNITY
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>8.1</b> You agree to indemnify, defend and hold harmless the Company, its officers, directors, employees, agents, licensors, suppliers and any third-party information providers to the Site from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this Agreement by you.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    TERMINATION
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>9.1</b> The Company may terminate this Agreement at any time without notice if you breach any of the terms of this Agreement.
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>9.2</b> Upon termination of this Agreement, you must immediately cease using the Site and the Data and destroy all copies of the Data in your possession.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    GOVERNING LAW
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>10.1</b> This Agreement shall be governed by and construed in accordance with the laws of the State of Maryland, United States of America.
                </Typography>
                <Typography variant="h5" component="li" gutterBottom sx={{ mt: 3 }}>
                    CONTACT INFORMATION
                </Typography>
                <Typography variant="body1" sx={{ pt: 1 }}>
                    <b>11.1</b> If you have any questions about this Agreement, please contact us at [TODO].
                </Typography>
            </Box>
        </Box>
    )
}
const CheckedCheckbox = () => {
    return (
        <ListItemIcon sx={{ mt: 0 }}>
            <Checkbox checked={true} sx={{
                '&.Mui-checked': {
                    color: 'rgba(0, 0, 0, 0.6)',
                },
            }} />
        </ListItemIcon>
    );
}

const DefinitionListItem = (props: { primary: string, children: React.ReactNode }) => {
    return (
        <ListItem alignItems="flex-start">
            <CheckedCheckbox />
            <ListItemText sx={{
                '& .MuiListItemText-secondary': {
                    fontSize: 'inherit',
                    fontWeight: '500',
                },
            }}
                primary={props.primary}
                secondary={props.children}
            />
        </ListItem>
    );
}

const RegularListItem = (props: { children: React.ReactNode }) => {
    return (
        <ListItem alignItems="flex-start">
            <CheckedCheckbox />
            <ListItemText primary={props.children} sx={{
                '& .MuiListItemText-primary': {
                    fontSize: 'inherit',
                    fontWeight: '500',
                    color: 'rgba(0, 0, 0, 0.6)',
                },
            }} />
        </ListItem>
    );
}
export default TermsPage;