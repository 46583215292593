import { Card, CardContent, Typography, Button, Grid, Box, Divider } from "@mui/material";
import React from "react";

/**
 * PricingCard component displays a subscription plan with a description and price.
 */
const PricingCard: React.FC<{
  id: string;
  description: string;
  price: number;
  quota: number;
  currency: string;
  isPopular: boolean;
  onCheckout: (priceId: string) => void;
}> = ({ id, description, price, quota, currency, isPopular, onCheckout }) => {
  return (
    <Grid item style={{ 'flexGrow': '1' }} md={12} lg={4} sm={12} xs={12}>
      <Card sx={{
        boxShadow: isPopular ? '0 4px 20px rgba(0, 0, 0, 0.1)' : '0 2px 10px rgba(0, 0, 0, 0.08)',
        borderColor: isPopular ? 'primary.main' : 'grey.300',
        borderWidth: isPopular ? 2 : 1,
        borderStyle: 'solid',
        backgroundColor: isPopular ? 'rgba(0, 0, 255, 0.05)' : 'white',
      }}>
        <CardContent>
          {isPopular && (
            <Typography
              variant="overline"
              align="center"
              color="primary"
              sx={{ display: 'block', fontWeight: 'bold' }}
            >
              Most Popular
            </Typography>
          )}
          <Typography variant="h5" align="center" gutterBottom>
            {description}
          </Typography>
          <Typography variant="h4" align="center" color="primary" gutterBottom>
            {`$${price}.00/mo (${currency.toLocaleUpperCase()})`}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" align="center" gutterBottom>
            {quota} records/month
          </Typography>
          <Typography variant="body2" align="center">
            ${(price / quota).toFixed(3)} per record
          </Typography>
          <Box display="flex" justifyContent="center" mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onCheckout(id)}
            >{`Subscribe to ${description}`}</Button>
          </Box>

        </CardContent>
      </Card>
    </Grid>
  );
};

export { PricingCard };
