import React, { ChangeEvent, useState, useEffect } from 'react';
import { CircularProgress, Grid, TextField, Button, Card, CardContent, Typography, Box, Input, Tooltip, Tabs, Tab, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { ExtendedNonprofitMatch, NonprofitMatchResponse } from '../types/donationInterfaces';
import { useNavigate, useLocation } from 'react-router-dom';

const NonprofitExtendedSearchPage: React.FC = () => {
    const [name, setName] = useState('')
    const [state, setState] = useState('');
    const [results, setResults] = useState<{ query: string, matches: ExtendedNonprofitMatch[]} | null>();
    const [isLoading, setIsLoading] = useState(false);
  
  
    const handleTryIt = async () => {
      setIsLoading(true);
      const response = await fetch(`/api/v1/admin/nonprofits?name=${name}&state=${state}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }
      });
      setIsLoading(false);
      if (!response.ok) {
        console.log(response);
        return
      } else {
        const results = await response.json();
        console.log(results);
        setResults(results);
      }
    }
  
    return (
      <Box>
        <Card sx={{ marginBottom: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>Extended Nonprofit Search</Typography>
            <Typography variant="body2" gutterBottom>
              Use this form to look up a nonprofit organization by entering the name. You can also add the state to narrow down results.
              Returns all nonprofits that match the search criteria that are in the database.
              Including those without reports.
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField fullWidth label="State" value={state} onChange={(e) => setState(e.target.value)}/>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleTryIt}>
                  Try It Now
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {isLoading && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        {/* Display Results */}
        {results && (
          <Card sx={{ marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h6" component="div">
                Nonprofit Search Results
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Donation Records</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Website</TableCell>
                    <TableCell>Match score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.matches.map((match, index) => (
                    <TableRow key={index}>
                      <TableCell>{match.nonprofit_name}</TableCell>
                      <TableCell>{match.donation_records}</TableCell>
                      <TableCell>{match.nonprofit_state}</TableCell>
                      <TableCell>{match.nonprofit_city}</TableCell>
                      <TableCell>{match.nonprofit_website}</TableCell>
                      <TableCell>{match.match_score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </Box>
    )
  }

export default NonprofitExtendedSearchPage;