import React from 'react';
import { Toolbar, CssBaseline, Box } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthProvider';
import { Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ApiKeyPage from './pages/ApiKeyPage';
import BillingPage from './pages/BillingPage';
import AccountPage from './pages/AccountPage';
import QueryPlayground from './pages/QueryPlayground';
import Sidebar from './layout/Sidebar';
import Register from './auth/register';
import Login from './auth/login';
import TermsPage from './pages/TermsPage';
import VerifyEmail from './auth/verifyEmail';
import ForgotPassword from './auth/forgotPassword';
import LogsPage from './pages/LogsPage';
import ApiDocs from './pages/ApiDocsPage';
import SupportChat from './components/SupportChat';
import NonprofitExtendedSearchPage from './pages/NonprofitSearchPage';
import BillingSuccess from './pages/BillingSuccessPage';

const App: React.FC = () => {
  const location = useLocation();

  // Pages without sidebar
  const hideSidebar = ['/login', '/register', '/terms', '/verify-email', '/forgot-password'];

  // Check if sidebar should be hidden
  const shouldHideSidebar = hideSidebar.some((path) => location.pathname.includes(path));

  // Wrapper for private routes
  function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
    console.log(auth);
    if (!auth.isAuthenticated) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }
  
    return children;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!shouldHideSidebar && <Sidebar />}  
      <Box component="main" sx={{ flexGrow: 1, p: shouldHideSidebar ? 0: 3 }}>
        {!shouldHideSidebar && <Toolbar />}
        <Routes>
          <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="/apikey" element={<RequireAuth><ApiKeyPage /></RequireAuth>} />
          <Route path="/apilogs" element={<RequireAuth><LogsPage /></RequireAuth>} />
          <Route path="/billing" element={<RequireAuth><BillingPage /></RequireAuth>} />
          <Route path="/billing/success" element={<RequireAuth><BillingSuccess /></RequireAuth>} />
          <Route path="/accountsettings" element={<RequireAuth><AccountPage /></RequireAuth>} />
          <Route path="/queryplayground" element={<RequireAuth><QueryPlayground /></RequireAuth>} />
          <Route path="/docs" element={<RequireAuth><ApiDocs /></RequireAuth>} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/nonprofitsearch" element={<RequireAuth><NonprofitExtendedSearchPage /></RequireAuth>} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Box>

      <SupportChat />
    </Box>
  );
};

export default App;
