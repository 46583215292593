import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch('/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                setSnackbarSeverity('success');
                setSnackbarMessage(data.message);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || 'Failed to reset password.');
            }
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Failed to reset password. Please try again.');
        } finally {
            setOpenSnackbar(true);
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Box component="img" src="/ff-logo-banner.jpeg" alt="logo" sx={{ height: 100, pb: 3 }} />
            <Typography variant="h4" gutterBottom>
                Forgot Password?
            </Typography>
            <Box
                component="form"
                sx={{ width: '100%', maxWidth: 400, mt: 2 }}
                onSubmit={handleSubmit}
            >
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    required
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Reset Password
                </Button>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
};

export default ForgotPassword;