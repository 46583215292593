import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

const VerifyEmail: React.FC = () => {
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`/auth/verify-email?token=${token}`);
        const data = await response.json();
        if (!response.ok) {
          setSnackbarSeverity('error');
          setSnackbarMessage(data.message || 'Verification failed.');
          setOpenSnackbar(true);
        } else {
          setSnackbarSeverity('success');
          setSnackbarMessage('Email verified successfully!');
          setOpenSnackbar(true);
          setVerified(true);
        }
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Verification failed. Please try again.');
        setOpenSnackbar(true);
      }
    };

    verifyEmail();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!verified) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Error verifying the email. Make sure that the link is correct.');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await fetch('/auth/set-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, password }),
      });

      const data = await response.json();
      if (response.ok) {
        setSnackbarSeverity('success');
        setSnackbarMessage('Password set successfully! Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.message || 'Failed to set password.');
      }
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to set password. Please try again.');
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Box component="img" src="/ff-logo-banner.jpeg" alt="logo" sx={{ height: 100, pb: 3 }} />
      <Typography variant="h4" gutterBottom>
        Set Your Password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ width: '100%', maxWidth: 400, mt: 2 }}
      >
        <TextField
          label="New Password"
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={!verified}
        >
          Set Password
        </Button>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VerifyEmail;
