import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Card, CardContent, Typography, Grid } from '@mui/material';
import { UserProfile } from '../types/userProfile';
import AlertMessage from '../components/AlertMessage';

interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}


function AccountPage() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [formData, setFormData] = useState<ChangePasswordFormData>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('error');

  useEffect(() => {
    // Fetch user profile data
    const fetchUserProfile = async () => {
      const response = await fetch('/account/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        console.error('Failed to fetch user profile data', response);
        if (response.status === 401) {
          navigate('/login');
        }
        return;
      }
      const data = await response.json();
      console.log(data);
      setUserProfile(data);
    };
    fetchUserProfile();

  }, []);

  const changePassword = async () => {
    if (formData.oldPassword === '' || formData.newPassword === '' || formData.confirmPassword === '') {
      setAlertMessage('Please fill out all fields');
      return;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setAlertMessage('Passwords do not match');
      return;
    }
    const response = await fetch('/account/password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      console.error('Error changing password:', response);
      if (response.status === 401) {
        navigate('/login');
        return;
      }
      setAlertMessage('Error changing password');
      return;
    } else {
      setAlertMessage('Password changed successfully');
      setAlertSeverity('success');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });
  };

  const closeAccount = async () => {
    const response = await fetch('/account/profile', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      console.error('Error closing account:', response);
      if (response.status === 401) {
        navigate('/login');
        return;
      }
      setAlertMessage('Error closing account');
      return;
    } else {
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  return (
    <>
      <AlertMessage message={alertMessage} onClose={() => setAlertMessage('')} open={alertMessage != ''} severity={alertSeverity}/>
      <Typography variant="h4" pb={2}>Account</Typography>

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" fontWeight={600}>Profile</Typography>
              <Typography variant="body1">Your email address is your identity on FF API app and is used to log in.</Typography>

            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">Email</Typography>
              <TextField fullWidth value={userProfile?.email} InputProps={{ readOnly: true }} />

              <Typography variant="subtitle1" pt={1}>Name</Typography>
              <Box display="flex" sx={{ gap: 2 }}>
                <TextField fullWidth value={userProfile?.first_name} InputProps={{ readOnly: true }} />
                <TextField fullWidth value={userProfile?.last_name} InputProps={{ readOnly: true }} />
              </Box>

              <Typography variant="subtitle1" pt={1}>Organization</Typography>
              <TextField fullWidth value={userProfile?.org_name} InputProps={{ readOnly: true }} />

              <Typography variant="subtitle1">Phone</Typography>
              <TextField fullWidth value={userProfile?.phone} InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{marginTop: "24px"}}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" fontWeight={600}>Password</Typography>
              <Typography variant="body1">Changing your password will also reset your API key.</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">Current Password</Typography>
              <TextField fullWidth type="password" name="oldPassword" value={formData.oldPassword} onChange={handleChange} />

              <Typography variant="subtitle1" pt={1}>New Password</Typography>
              <TextField fullWidth type="password"  name="newPassword" value={formData.newPassword} onChange={handleChange} />

              <Typography variant="subtitle1" pt={1}>Confirm Password</Typography>
              <TextField fullWidth type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
              <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={changePassword}>Change Password</Button>
            </Grid>
            </Grid>
        </CardContent>
      </Card>

      <Card sx={{marginTop: "24px"}}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" fontWeight={600} sx={{color: "red"}}>Close Account</Typography>
              <Typography variant="body1">Warning: Closing your account is irreversible.</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
            <Button variant="contained" color="warning" sx={{ mt: 2 }} onClick={closeAccount}>Close this account</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default AccountPage;
