import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Box, Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


interface DataStats {
  nonprofits: number;
  reports: number;
  donations: number;
}


function Dashboard() {
  const navigate = useNavigate();
  const [dataStats, setDataStats] = useState<DataStats | null>(null);

  useEffect(() => {
    // Fetch data stats
    const fetchDataStats = async () => {
      try {
        const response = await fetch('/data/stats', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          if (response.status === 401) {
            // If the access token is expired, redirect to login page
            navigate('/login');
            return;
          }
          console.error('Error fetching data stats:', response);
          return;
        }
        const data = await response.json();
        console.log('Data stats:', data);
        setDataStats(data);
      } catch (error) {
        // If the access token is expired, redirect to login page

        console.error('Error fetching data stats:', error);
      }
    }
    fetchDataStats();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      {/* Stats Section */}
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>Data Stats</Typography>
        <Grid container spacing={3}>
          {/* Health Status */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">U.S. Nonprofits</Typography>
                <Typography variant="body1">{7620||dataStats?.nonprofits.toLocaleString()}</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Records Served */}
          <Grid item xs={12} md={5}>
            <Card>
              <CardContent>
                <Typography variant="h6">Charitable Giving Records</Typography>
                <Typography variant="body1">{5591941||dataStats?.donations.toLocaleString()}</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Freshness */}
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Total reports</Typography>
                <Typography variant="body1">{10326||dataStats?.reports.toLocaleString()}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Next Steps and Smaller Cards */}
      <Box>
        <Typography variant="h5" gutterBottom>Actions & Information</Typography>
        <Grid container spacing={3}>
          {/* Next Steps - Full Width */}
          {/* Try Now */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Try Now</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to use the API with our query playground & inline documentation. Start making your first API calls.
                </Typography>
                <Button variant="contained" color="primary" href="/queryplayground">
                  Go to Query Playground
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Verifying Donor History */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Verifying Donor History</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to quickly check if someone has a history of charitable giving.
                </Typography>
                {/* <Button variant="contained" color="primary" onClick={handleVerifyDonorHistory}>
                  Learn More
                </Button> */}
              </CardContent>
            </Card>
          </Grid>

          {/* Bulk Prospect Research */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Bulk Prospect Research</Typography>
                <Typography variant="body2" gutterBottom>
                  Learn how to upload a list of names to see which are tied to major gifts.
                </Typography>
                {/* <Button variant="contained" color="primary" onClick={handleBulkProspectResearch}>
                  Learn More
                </Button> */}
              </CardContent>
            </Card>
          </Grid>

          {/* Help */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Help</Typography>
                <Typography variant="body2">Get support or view documentation.</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Account / API Keys */}
          <Grid item xs={12} md={6}>
            <Link
              component={RouterLink}
              to="/apikey"
              underline="none"
              style={{ textDecoration: 'none' }} // Ensures the link doesn't have default underline or styling
            >
              <Card sx={{ cursor: "pointer" }}>
                <CardContent>
                  <Typography variant="h6">Account / API Keys</Typography>
                  <Typography variant="body2">Manage your account and API keys.</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Dashboard;
