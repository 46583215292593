import React from 'react';
import { Box, Typography } from '@mui/material';
import { LazyLog } from 'react-lazylog';

const LogsPage: React.FC = () => {
    const fetchOptions: RequestInit = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    };
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Logs
            </Typography>
            <LazyLog url="http://localhost:3001/account/logs" fetchOptions={fetchOptions} height={600} />
        </Box>
    );
}

export default LogsPage;