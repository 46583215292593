import React, { useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';
import AlertMessage from './AlertMessage';

const SupportChat: React.FC = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false); // Controls visibility of chat window
    const [message, setMessage] = useState(''); // Stores the user's message
    const [alertMessage, setAlertMessage] = useState(''); // System message
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success'); // Severity of the system message

    // Handle sending the message (could send an email here)
    const handleSendMessage = () => {
        if (message.trim() === '') return;

        // Simulate sending email to support
        const sendMessage = async () => {
            const response = await fetch('/account/support', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ message }),
            });

            if (!response.ok) {
                console.error('Failed to send message');
                if (response.status === 401) {
                    navigate('/login');
                    return;
                }
                setAlertMessage('Failed to send message');
                setAlertSeverity('error');
            } else {
                setAlertMessage('Message sent successfully');
                setAlertSeverity('success');
            }
        }
        sendMessage();

        // Clear message after sending
        setMessage('');
    };

    return (
        <>
            <AlertMessage message={alertMessage} onClose={() => setAlertMessage('')} open={alertMessage != ''} severity={alertSeverity}/>
            <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>

                {/* Chat Icon Button */}
                <IconButton
                    onClick={() => setOpen(!open)}
                    sx={{
                        backgroundColor: '#333', // Dark circle background
                        color: '#fff', // White icon
                        width: 64, // Larger size
                        height: 64, // Larger size
                        '&:hover': {
                            backgroundColor: '#555', // Slightly lighter on hover
                        },
                    }}
                >
                    <ChatIcon sx={{ fontSize: 32 }} /> {/* Larger icon */}
                </IconButton>

                {/* Chat Window */}
                {open && (
                    <Paper elevation={3} sx={{ position: 'absolute', bottom: 60, right: 0, width: 300, padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Contact Support
                        </Typography>
                        <TextField
                            label="Type your message"
                            multiline
                            rows={4}
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            variant="outlined"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                            onClick={handleSendMessage}
                            sx={{ mt: 2 }}
                        >
                            Send
                        </Button>
                    </Paper>
                )}
            </Box>
        </>
    );
};

export default SupportChat;
