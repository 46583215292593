import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link, TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../context/AuthProvider';

interface LoginFormData {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const from = location.state?.from || { pathname: '/' };
    const [formData, setFormData] = useState<LoginFormData>({
        email: '',
        password: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch('/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (response.ok) {
                setSnackbarSeverity('success');
                setSnackbarMessage('Login successful!');
                // Save the token to localStorage or sessionStorage
                localStorage.setItem('token', data.token);
                auth.login(data.token);
                navigate(from.pathname);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message || 'Login failed.');
            }
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Login failed. Please try again.');
        } finally {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >

        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            width="50%"
        >

            <Box component="img" src="/ff-logo-banner.jpeg" alt="logo" sx={{ height: 100, pb: 3 }} />
            <Typography variant="h4" gutterBottom>
                Login to your account
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ width: '100%', maxWidth: 400, mt: 2 }}
            >
                <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Login
                </Button>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Don't have an account?{' '}
                    <Link component={RouterLink} to="/register">
                        Sign up
                    </Link>
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Forgot your password?{' '}
                    <Link component={RouterLink} to="/forgot-password">
                        Reset password
                    </Link>
                </Typography>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
        <Box
            
        sx={{
            width: '50%',
            backgroundImage: 'url(/login-bg.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}/>
        </Box>
    );
};

export default Login;
