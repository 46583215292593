import { IProjectWindow, isBrowser } from '../types/captchaInterfaces';

export const getProjectWindow = () =>
    isBrowser ? (window as unknown as IProjectWindow) : undefined;

export const getHeadTag = () => {
    const window = getProjectWindow();
    if (window) {
        return window.document.getElementsByTagName('head')[0];
    }
    return undefined;
};

export const injectScript = (id: string, src: string, onload?: () => void) => {
    const script = document.createElement("script");
    script.id = id;
    script.src = src;
    script.addEventListener("load", onload || (() => { }));
    document.body.appendChild(script);
};

export const removeScript = (id: string) => {
    const window = getProjectWindow();
    if (window) {
        const scriptTag = window.document.getElementById(id);
        if (scriptTag) {
            scriptTag.remove();
            return true;
        }
    }
    return false;
};
