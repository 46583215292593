import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Alert,
} from '@mui/material';

// Define types for the session details
interface Customer {
    name: string;
    email: string;
}

interface Subscription {
    current_period_start: number;
    current_period_end: number;
}

interface SessionDetails {
    status: string;
    customer_details: Customer;
    subscription: Subscription | null;
}

const BillingSuccess: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [sessionDetails, setSessionDetails] = useState<SessionDetails | null>(null);
    // TODO: #17 Implement error handling for the billing success page
    const [error, setError] = useState<string | null>(null);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    useEffect(() => {
        const fetchSessionDetails = async () => {
            try {
                const response = await fetch(`/billing/checkout?session_id=${sessionId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        }
                    }
                );
                if (!response.ok) {
                    console.error(response);
                }
                const data: SessionDetails = await response.json();
                setSessionDetails(data);
            } catch (err) {
                setError('Error fetching session details');
            } finally {
                setLoading(false);
            }
        };

        fetchSessionDetails();
    }, [sessionId]);


    return (
        <Container maxWidth="md">
            {loading && <CircularProgress />}
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                        Thank you for your business!
                    </Typography>
                    {sessionDetails && (
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            Subscription Details
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Payment session status:</strong> {sessionDetails.status}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Customer Name:</strong> {sessionDetails.customer_details.name}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Email:</strong> {sessionDetails.customer_details.email}
                                        </Typography>
                                        {sessionDetails.subscription && (
                                            <>
                                            <Typography variant="body1">
                                                <strong>Subscription Start Date:</strong>{' '}
                                                {new Date(sessionDetails.subscription.current_period_start * 1000).toLocaleDateString()}
                                            </Typography>
                                            <Typography variant="body1">
                                                <strong>Next Billing Date:</strong>{' '}
                                                {new Date(sessionDetails.subscription.current_period_end * 1000).toLocaleDateString()}
                                            </Typography>
                                            </>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                            {sessionDetails.status === 'complete' && (
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                Next Steps
                                            </Typography>
                                            <Typography variant="body1">
                                                You now have access to our API. Please visit your dashboard to manage your API keys and view usage details.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};

export default BillingSuccess;
